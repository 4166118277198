import './Nav.css'

export default function Nav() {
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom navbar-dark">
    <div className="container">
        <a className="navbar-brand" href="/"><img src="assets/img/logo.webp" height="50px" alt=""/></a>
    </div>
</nav>
    );
}