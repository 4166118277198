
export default function Adventure() {
    return(
    <section>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 order-lg-2">
                    <div className="p-5">
                        <img className="w-100 mb-4" src="assets/img/isthgulposter.webp" alt="The great sword of Isthgul Moive Poster"/>
                    </div>
                </div>
                <div className="col-lg-6 order-lg-1">
                    <div className="p-5 d-grid">
                        <h2 className="display-4">Join the adventure...</h2>
                        <p>Join us in the magical land of Isthgul where Esirnussheen siblings, Odion and Omolara discover ancient stories about their past, kept secret for generations.</p>


                        <p>After a successful run of screenings where over 1500 people watched the ‘The Great Sword of Isthgul’ on the big screen, Winton School’s very own feature length movie is now available online! </p>
                        <p>Follow the link below. Please note the rent or buy options are in US dollars. The rent price is NZ $10 and to buy (and keep forever!) is NZ $20. Thank you for supporting Winton School. Enjoy the movie!</p>
                        <a className="btn btn-primary js-center w-50 text-white" href="https://vimeo.com/ondemand/thegreatswordofisthgul" target="_blank" rel="noreferrer">Watch Online</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}