import './Footer.css';

export default function Footer() {
    return (
        <div className="footer p-4">
            <div className="container">
                <div className="row text-center">
                    <div className="col-xl-2 col-lg-2 offset-lg-4 offset-lg-4  col-sm-4 col-xs-4 d-grid text-center">
                        <h5>Partners</h5>
                        <a className="footer-text" href="https://www.winton.school.nz/" target="_blank" rel="noreferrer">Winton School</a>
                        <a className="footer-text" href="https://recce.co.nz" target="_blank" rel="noreferrer">Recce</a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-xs-4 d-grid text-center">
                        <h5>Socials</h5>
                        <a className="footer-text" href="https://www.facebook.com/WintonSchoolMovie" target="_blank" rel="noreferrer">Winton School Movie</a>
                        <a className="footer-text" href="https://www.facebook.com/search/top?q=recce%20nz" target="_blank" rel="noreferrer">Recce</a>
                    </div>
                </div>
            </div>
        </div>
    )
}