import './Banner.css';

export default function Banner() {
    return (
        <header className="text-center text-white masthead-film" aria-label="The Great Sword of Isthgul">
    <div className="masthead-content">
        <div className="container">
            <img className="masthead-heading mb-0 w-100" src="assets/img/logo.webp" alt=""/>
            <div className="d-flex flex-column justify-content-center ">
                <a className="btn btn-primary btn-xl rounded-pill mt-5 w-10 align-self-center p-4 text-white" role="button" href="https://vimeo.com/ondemand/thegreatswordofisthgul" target="_blank" rel="noreferrer">Watch Online Here</a>
            </div>
            <div className="d-flex flex-column justify-content-center ">
                <a className="btn btn-primary btn-xl rounded-pill mt-5 w-10 align-self-center p-4 text-white" role="button" href="https://www.winton.school.nz/" target="_blank" rel="noreferrer">Check out Winton School</a>
            </div>
        </div>
    </div>
    <div className="bg-circle-1 bg-circle"></div>
    <div className="bg-circle-2 bg-circle"></div>
    <div className="bg-circle-3 bg-circle"></div>
    <div className="bg-circle-4 bg-circle"></div>
</header>
    );
}