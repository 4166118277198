export default function Sponsors() {
    return (
        <section className="border p-2" >
            <div className="container">
                <div className="row text-center m-4">
                    <h2>Made Possible by</h2>
                </div>
                <div className="row d-flex flex-wrap align-items-center m-2">
                    <div className="col-10 col-lg-8 offset-lg-2 offset-xl-2">
                        <a href="https://recce.co.nz" target="_blank" rel="noreferrer">
                            <img className="w-100" src="assets/img/recce.webp" alt="Recce"/>
                        </a>
                    </div>
                </div>
                <div className="row d-flex flex-wrap align-items-center m-2">
                    <div className="col-2 col-lg-2 offset-lg-1 offset-xl-1">
                        <a href="https://digitalstock.co.nz/" target="_blank" rel="noreferrer">
                            <img className="w-100" src="assets/img/logos/DS_Logo.webp" alt="Digital Stock"/>
                        </a>
                    </div>
                    <div className="col-2 col-lg-2">
                        <a href="https://www.sbsbank.co.nz/" target="_blank" rel="noreferrer">
                            <img className="w-100" src="assets/img/logos/sbs.webp" alt="SBS"/>
                        </a>
                    </div>
                    <div className="col-2 col-lg-2">
                        <a href="https://www.newworld.co.nz/" target="_blank" rel="noreferrer">
                            <img className="w-100" src="assets/img/logos/newworld.webp" alt="New World"/>
                        </a>
                    </div>
                    <div className="col-2 col-lg-2">
                        <a href="https://www.i-cue.co.nz/" target="_blank" rel="noreferrer">
                            <img className="w-100" src="assets/img/logos/icue.webp" alt="I-Cue"/>
                        </a>
                    </div>
                    <div className="col-2 col-lg-2">
                        <a href="https://gobus.co.nz/" target="_blank" rel="noreferrer">
                            <img className="w-100" src="assets/img/logos/gobus.webp" alt="Go Bus"/>
                        </a>
                    </div>
                </div>
            </div>
    </section >
    )
}