export default function Overview() {
    return (
        <section>
            <div className="container mt-4">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-lg-4 col-sm-8 col-xs-8 mt-4">
                        <div className="p-5"><img className="img-fluid" src="assets/img/ppl.webp" alt="movie still"/></div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-8 col-xs-8   text-center mt-4 text-center">
                        <h2>In the magical land of Isthgul…</h2>
                        <p>
                            Esirnussheen siblings, Odion and Omolara discover ancient stories about their past, kept secret for generations.
                        </p>
                        <p>
                            Intrigued by the possibilities this newfound knowledge could have, they set off on a brave and daring quest, determined to learn the truth and bring hope to their village.
                        </p>
                        <p>
                            Their courage and ambition leads them on a perilous adventure through the many lands of Isthgul, befriending marvellous creatures and warding off those with more evil intent.
                        </p>
                        <p>
                            Will their curiosity and meddling of a mysterious and dark past be their downfall or will their unwavering resolve bring them the prospects and answers they are searching for?
                        </p>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-8 col-xs-8 mt-4">
                        <div className="p-5"><img className="img-fluid" src="assets/img/warrior.webp" alt="warrior"/></div>
                    </div>
                </div>
            </div>
        </section>
    )
}