export default function Trailer() {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <iframe src="https://www.youtube.com/embed/fSWDjQ9YNU4" title="The Great Sword of Isthgul Trailer" height="700" />
                </div>
                <div className="row align-items-center">

                    <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-xs-12 offset-lg-2 offset-xl-3">
                        <div className="p-5 d-grid">
                            <h2 className="display-4">A full length fantasy adventure film</h2>
                            <p>
                                featuring all 275 students of Winton School and filmed in locations across Southland and Fiordland.
                                Written and directed by Principal, Steve Wadsworth and in association with Recce NZ, made by the staff, students and wider Southland community.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <iframe src="https://www.youtube.com/embed/ihVraHC2Yug" title="The Great Sword of Isthgul Trailer 2" height="700" />
                </div>
            </div>
        </section>

    )
}