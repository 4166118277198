import Banner from "./UI/Banner/Banner";
import Nav from "./UI/Nav/Nav";
import Adventure from "./UI/Sections/Adventure/Adventure";

import Footer from "./UI/Footer/Footer";
import Trailer from "./UI/Sections/Trailer/Trailer";
import Sponsors from "./UI/Sections/Sponsors/Sponsors";
import Overview from "./UI/Sections/Overview/Overview";


export default function MyApp() {
  return (
    <div>
      <Nav/>
      <Banner/>
      <div className="container">
        <Adventure/>
        <Trailer/>
        </div>
        <Sponsors/>
        <Overview/>
        <Footer/>
    </div>
    
  );
}
